<template>
  <div>
    <systemTemplateTop/>
  </div>
</template>

<script>
import systemTemplateTop from './components/systemTemplateTop'
import {getSystemTemplates} from "@/api/systemTemplate";

export default {
  name: "index",
  data: () => ({
    page: 1,
    perPage: 10,
    keyword: '',
    synthesisTimeBetween: [],
    systemTemplateList: [],
    meta: {}
  }),
  components: {
    systemTemplateTop
  },
  computed: {},
  watch: {},
  mounted() {
    this.getSystemTemplates()
  },
  methods: {
    async getSystemTemplates() {
      try {
        const {data} = await getSystemTemplates({
          page: this.page,
          perPage: this.perPage,
          keyword: this.keyword,
          synthesisTimeBetween: this.synthesisTimeBetween
        })
        this.systemTemplateList = data?.data
        this.meta = data?.meta
      } catch (e) {
        this.$message.error(e || '服务器错误')
      }
    }
  }
}
</script>

<style scoped lang='less'>

</style>