<template>
  <div>
    <div class="design-top">
      <!-- 左边 -->
      <div class="left">


        <!-- 日期 -->
        <div class="date">

          <ul class="list">
            <li class="text">日期</li>
            <li class="date-list" v-for="(item1,index1) in dateList" :key="index1"
                :class="{'active':dateActive===index1}" @click="date(index1,item1)">
              {{ item1 }}
            </li>
          </ul>
        </div>
        <!-- 日期结束 -->

      </div>
      <!-- 左边结束 -->
      <div class="right">
        <!--        <button class="design-btn" @click="design"><i class="el-icon-circle-plus-outline"></i>开始设计</button>-->
        <!-- <button class="apply-btn">申请模板开发</button> -->
      </div>
    </div>

      <div class="search">
        <el-input style="width: 500px;" v-model="keyWord" placeholder="名称" @change="search">
          <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
        </el-input>
    </div>
  </div>
</template>

<script>

export default {
  name: 'systemTemplateTop',
  data() {
    return {
      dateList: ['所有', '今天', '昨天', '本周', '本月', '上月'],
      dateActive: 0,  //选中日期
      keyWord:'',
    }
  },

  created() {

  },
  mounted() {
  },
  methods: {

    // 获取时间
    date(i, time) {

      this.dateActive = i

    },
    search() {
      console.log(this.keyWord)
      this.$emit('keyWord')
    },

  }
}
</script>

<style scoped lang="less">
ul {
  list-style-type: none;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  display: flex;
  flex-wrap: wrap;
}

.search {

  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.design-top {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #D8D8D8;
  padding-bottom: 15px;
  // position: relative;
  .left {
    font-size: 14px;

    div {
      margin-bottom: 16px;
    }
    .text {
      font-size: 14px;
      margin-right: 20px;
      color: #666666;
    }
    .active {
      color: #fa4a14;
    }
    .date-list {
      margin-right: 20px;
      // padding: 4px;
      cursor: pointer;
    }
  }
  .right {
    //  position: absolute;
    //  top: 104px;
    //  right: 62px;
    .design-btn {
      width: 150px;
      height: 40px;
      background: #FA4A14;
      box-shadow: 0 0 10px 0 #F93A00;
      border-radius: 5px;
      border: 0;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      outline: none;
      font-size: 18px;

    }
  }
}
















</style>
