import request from "@/utils/request";
// 交易汇总列表
export const getSystemTemplates = (
    {
        perPage,
        page,
        keyword,
        synthesisTimeBetween
    }
    ,
    callback
) => {
    const params = {
        perPage,
        page,
        keyword : keyword || undefined,
        synthesisTimeBetween:synthesisTimeBetween.length > 0 ? synthesisTimeBetween : undefined
    }
    return request('design/getSystemTemplates', {params, method: 'get'}, callback)
}